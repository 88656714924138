import React from 'react';

const Footer = ({ about }) => {
  return (
    <footer className="footer">
      <div className="footer__content container">
        <div className="footer__left">
          <img src="images/logo-inverted.svg" alt="logo" className="footer__logo" />
        </div>
        <div className="footer__right">
          <div className="footer__items">
            <div className="footer__item">
              <i className="footer__icon fal fa-map-marker"></i>
              <p className="paragraph paragraph--small paragraph--300">
                Staes Reno <br />
                { about.address[0].street }<br />
                { about.address[0].postcode } { about.address[0].city }
              </p>
            </div>
            <div className="footer__item">
              <i className="footer__icon fal fa-phone"></i>
              <p className="paragraph paragraph--small paragraph--300">
                { about.phone }
              </p>
            </div>
          </div>
          <div className="footer__items">
            <div className="footer__item">
              <i className="footer__icon fal fa-envelope"></i>
              <p className="paragraph paragraph--small paragraph--300">
                {about.email}
              </p>
            </div>
            <div className="footer__item">
              <i className="footer__icon fal fa-percent"></i>
              <p className="paragraph paragraph--small paragraph--300">
                {about.vatNumber}
              </p>
            </div>
            <div className="footer__item">
              <i className="footer__icon fab fa-facebook"></i>
              <a rel="noreferrer" href={about.facebook} target="_blank" className="footer__link paragraph paragraph--link paragraph--small paragraph--300">
                Staes Reno
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;