import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import ImageStack from '../image-stack.component';

const ProjectCard = ({ project }) => (
  <div className={`projects__card ${project.images.length === 0 && 'projects__card--no-images'}`}>
    <div className="projects__card__left">
      <h3 className="projects__card__title">{project.heading}</h3>
      {
        project._rawDescription && project._rawDescription.length &&
          <BlockContent renderContainerOnSingleChild={true} className="projects__card__description" blocks={project._rawDescription}></BlockContent>
      }
      <div className="projects__card__tags">
        {
          project.location && <div className="projects__card__tag">
            <i className="projects__card__tag__icon fal fa-map-marker"></i>
            <p className="paragraph paragraph--small paragraph--neutral-600">{project.location}</p>
          </div>
        }
        <div className="projects__card__tag">
          <i className="projects__card__tag__icon fal fa-tag"></i>
          <p className="paragraph paragraph--small paragraph--neutral-600">{project.tag}</p>
        </div>
      </div>
    </div>
    {
      project.images.length > 0 &&
        <div className="projects__card__right">
          <ImageStack images={project.images}></ImageStack>
        </div>
    }
  </div>
);

export default ProjectCard;