import React, { useState } from 'react';

import Navigation from './navigation.component';

const Header = () => {
  const [state, setState] = useState({ isHamburgerMenuOpen: false });

  const toggleHamburgerMenu = () => setState({ isHamburgerMenuOpen: !state.isHamburgerMenuOpen });

  return (
    <header className="header">
      <div className="header__content container">
        <div className="header__left">
          <img src='images/logo.svg' alt="Staes Reno" className="header__logo" />
        </div>
        <button onClick={toggleHamburgerMenu} className="hamburger-menu">
          <i className={state.isHamburgerMenuOpen ? 'fal fa-times' : 'fal fa-bars'}></i>
        </button>
        <div className="header__navigation">
          <Navigation css='navigation'></Navigation>
        </div>
      </div>
      { state.isHamburgerMenuOpen && <div className="hamburger-menu__content"><Navigation css='navigation-mobile'></Navigation></div> }
    </header>
  )
};

export default Header;