import React from 'react';

import Header from './header.component';
import Seo from './seo.component';

import './../styles/main.scss';

const Layout = ({ children }) => (
  <div className="page">
    <Seo></Seo>
    <div className="page__header">
      <Header></Header>
    </div>
    <div className="page__content">{children}</div>
  </div>
)

export default Layout;