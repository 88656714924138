import React, { useRef, useState } from 'react';

const Contact = () => {
  const formReference = useRef(null);
  const [state, setState] = useState({ email: undefined, project: undefined, fullName: undefined, phone: undefined, errors: {} });

  const handleChange = (event, property) => {
    setState({ ...state, [property]: event.target.value, errors: {} });
  }

  const submitted = () => {
    const form = formReference.current;
    let localState = { ...state };

    const { fullName, email, project, phone } = localState;

    if (fullName === undefined) localState = { ...localState, errors: { ...localState.errors, fullName: 'Naam is een verplicht veld' } };
    if (phone === undefined) localState = { ...localState, errors: { ...localState.errors, phone: 'GSM Nummer is een verplicht veld' } };
    if (email === undefined) localState = { ...localState, errors: { ...localState.errors, email: 'Email is een verplicht veld' } };
    if (project === undefined) localState = { ...localState, errors: { ...localState.errors, project: 'Project is een verplicht veld' } };

    const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    if (!isValidEmail) localState = { ...localState, errors: { ...localState.errors, email: 'Email is geen geldig email' } };

    const hasErrors = Object.keys(localState.errors).length > 0;
    if (!hasErrors) {
      form.submit();
    } else {
      setState(localState);
    }
  }

  return (
    <section className="contact" id="contact">
    <div className="contact__content container">
      <h2 className="heading-secondary">Contact</h2>
      <form ref={formReference} id='contact-form' method='post' action='https://formspree.io/f/mbjbpnbv' className="contact__form">
        <div className="form__group">
          <label htmlFor="name" className="form__label">Naam</label>
          <input onChange={(event) => handleChange(event, 'fullName')} id="name" name="name" type="text" className="form__input" />
          {state.errors['fullName'] && <p className='form__error'>{state.errors['fullName']}</p>}
        </div>
        <div className="form__group">
          <label htmlFor="email" className="form__label">Email</label>
          <input onChange={(event) => handleChange(event, 'email')} id="email" name="email" type="email" className="form__input" />
          {state.errors['email'] && <p className='form__error'>{state.errors['email']}</p>}
        </div>
        <div className="form__group">
          <label htmlFor="phone" className="form__label">GSM Nummer</label>
          <input onChange={(event) => handleChange(event, 'phone')} id="phone" name="phone" type="text" className="form__input" />
          {state.errors['phone'] && <p className='form__error'>{state.errors['phone']}</p>}
        </div>
        <div className="form__group contact__form__row">
          <label htmlFor="project" className="form__label">Project</label>
          <textarea onChange={(event) => handleChange(event, 'project')} name="project" placeholder='Vertel mij wat meer over uw project' className="form__text-area"></textarea>
          {state.errors['project'] && <p className='form__error'>{state.errors['project']}</p>}
        </div>
        <div className="g-recaptcha" data-sitekey="6LccyOMiAAAAACwsMhySLgxr7thKvVY6D8Xtvh0q"></div>
      </form>
      <button onClick={() => submitted()} className="contact__button button button--primary">Verzend</button>
    </div>
  </section>
  )
}

export default Contact;