import React, { useState } from 'react';

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ImageStack = ({ images }) => {
  const slides = images.map((image) => ({ src: image.asset.url, key: image.asset._id }));
  const mainImage = images[0];
  const otherImages = images.slice(1, 3);

  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return (
    <div className="image-stack">
      <Lightbox slides={slides} open={isOpen} close={() => close()}></Lightbox>
      <img onClick={open} src={mainImage.asset.url} alt="main" className="image-stack__image" />
      <div className="image-stack__carousel">
        {
          otherImages.map((image) => {
            return (<img key={image.asset._id} onClick={open} src={image.asset.url} alt={image.asset._id} className="image-stack__image image-stack__image--small" />)
          })
        }
      </div>
  </div>
  )
};

export default ImageStack;