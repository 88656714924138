import React from 'react';
import PortableText from '@sanity/block-content-to-react';
import ShowMoreText from 'react-show-more-text';

const About = ({ about }) => {
  return (
    <section className="about" id="about">
      <div className="about__content container">
        <div className="about__left">
          <h2 className="heading-secondary">Wie ben ik?</h2>
          <ShowMoreText lines={7} className='about__description paragraph paragraph--900' more='Toon Meer' less='Toon Minder'>
            <PortableText className="about__description paragraph paragraph--900" renderContainerOnSingleChild={true} blocks={about._rawDescription}>
            </PortableText>
          </ShowMoreText>
          <div className="about__contact">
            <div className="about__contact__item">
              <i className="about__contact__icon fal fa-phone"></i>
              <p className="paragraph paragraph--300 paragraph--neutral-900">
                {about.phone}
              </p>
            </div>
            <div className="about__contact__item">
              <i className="about__contact__icon fal fa-envelope"></i>
              <p className="paragraph paragraph--300 paragraph--neutral-900">
                {about.email}
              </p>
            </div>
          </div>
        </div>
        <div className="about__right">
          <img src={about.avatar.asset.url} alt="Avatar" className="about__avatar" />
        </div>
      </div>
    </section>
  )
};

export default About;