import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout.component';
import HeroSection from '../components/hero-section.component';
import About from '../components/about.component';
import ProjectList from '../components/projects/project-list.component';
import Contact from '../components/contact';
import Footer from '../components/footer.component';

const IndexPage = ({ data }) => {
  const heroSection = 'SanityHero';
  const aboutSection = 'SanityAbout';
  const projectCategory = 'SanityProjectCategory';

  const pageNodes = data.allSanityPage.nodes;
  const builder = pageNodes[0].pageBuilder;

  const heroSections = builder.filter((i) => i.__typename === heroSection);
  const aboutSections = builder.filter((i) => i.__typename === aboutSection);
  const projectCategories = builder.filter((i) => i.__typename === projectCategory);

  const projects = data.allSanityProject.nodes;

  return (
    <Layout>
      <HeroSection hero={heroSections[0]}></HeroSection>
      <About about={aboutSections[0]}></About>
      <ProjectList categories={projectCategories} projects={projects}></ProjectList>
      <Contact></Contact>
      <Footer about={aboutSections[0]}></Footer>
    </Layout>
  )
}

export const query = graphql`query {
  allSanityPage {
    nodes {
			pageBuilder {
				__typename ... on SanityAbout {
					logo { asset { url altText } }
        	_rawDescription
        	avatar { asset { url altText } }
        	phone,
        	email
        	vatNumber
        	address {
          	street,
          	city,
          	postcode
        	}
        	facebook
        }
        __typename ... on SanityHero {
					heading
        	tagline
        	callToActionText
        	image { asset { url altText } }
        }
        __typename ... on SanityProjectCategory {
          _key
          _type
          _rawDescription
          name
        }
      }
    }
  }
  allSanityProject {
    nodes {
      _rawDescription
      heading
      id
      location
      tag
      _createdAt
      images {
        asset {
          url
          _id
        }
      }
    }
  }
}`;
export default IndexPage;