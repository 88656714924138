import React, { useState } from 'react';
import { firstBy } from 'thenby';
import BlockContent from '@sanity/block-content-to-react';

import ProjectCard from './project-card.component';

const ProjectList = ({ projects, categories }) => {
  const availableFilters = ['Alle Projecten'].concat(categories.map((c) => c.name));
  const sortProjects = (projects) => projects.sort(firstBy((p) => p._createdAt, { direction: 'desc' }));

  const [active, setActive] = useState('Alle Projecten');
  const [cards, setCards] = useState(sortProjects(projects));
  const [entities] = useState(
    {
      ...categories.reduce((previous, current) => ({ ...previous, [current.name]: current }), {}),
      'Alle Projecten': { _rawDescription: [] }
    }
  );

  const filterProjects = (active) => {
    setActive(active);

    if (active === 'Alle Projecten') {
       setCards(sortProjects(projects));
    } else {
      const filtered = projects.filter((p) => p.tag === active);
      setCards(sortProjects(filtered));
    }
  }


  return (
    <section className="projects" id="projects">
      <div className="projects__content container">
        <h2 className="heading-secondary">Projecten</h2>
        <div className="projects__filters">
          <div className="projects__filters__tags">
            {
            availableFilters.map((filter) => {
              return (<p key={filter} onClick={() => filterProjects(filter)} className={`projects__filters__tags__item ${active === filter && 'projects__filters__tags__item--active'}`}>{ filter }</p>)
            })
          }
          </div>
          {
            entities[active]._rawDescription.length > 0 &&
              <div className="projects__description">
                <BlockContent blocks={entities[active]._rawDescription}></BlockContent>
              </div>
          }
        </div>
        <div className="projects__cards">
          {
            cards.map((card) => {
              return (<ProjectCard key={card.id} project={card}></ProjectCard>)
            })
          }
        </div>
      </div>
    </section>
  )
};

export default ProjectList;