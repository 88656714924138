import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet"

const Seo = () => {
  const { site } = useStaticQuery(graphql`query {
      site {
        siteMetadata {
          title
          description
          author
          keywords
        }
      }
    }`);

   return (
    <Helmet
      htmlAttributes={{ lang: site.siteMetadata.lang }}
      title={site.siteMetadata.title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: site.siteMetadata.description,
        },
      ]
        .concat(
          site.siteMetadata.keywords.length > 0
            ? {
                name: `keywords`,
                content: site.siteMetadata.keywords.join(`, `),
              }
            : []
        )}
    />)
};

export default Seo;