import React from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';

const HeroSection = ({ hero }) => (
  <section className="hero">
    <div className="hero__content container">
      <div className="hero__left">
        <h1 className="heading-primary heading-primary--white">{ hero.heading }</h1>
        <p className="paragraph paragraph--neutral-50">
          {hero.tagline}
        </p>
      </div>
      <div className="hero__right">
        <AnchorLink to='/#contact'>
          <button className="button button--primary">{hero.callToActionText}</button>
        </AnchorLink>
      </div>
    </div>
  </section>
);

export default HeroSection;