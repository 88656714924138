import React from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Navigation = ({ css }) => (
  <nav className={css}>
    <ul className={`${css}__list`}>
      <li className={`${css}__item`}>
        <AnchorLink className={`${css}__link`} to='/#about'>Wie ben ik?</AnchorLink>
      </li>
      <li className={`${css}__item`}>
        <AnchorLink className={`${css}__link`} to='/#projects'>Projecten</AnchorLink>
      </li>
      <li className={`${css}__item`}>
        <AnchorLink className={`${css}__link`} to='/#contact'>Contact</AnchorLink>
      </li>
    </ul>
  </nav>
);

export default Navigation;